/* Business Card Maker website
*/

(function() {
  'use strict';

  $(document).foundation();
})();

function numberOnly(id) {
  var element = document.getElementById(id);
  var regex = /[^0-9]/gi;
  element.value = element.value.replace(regex, '');
}
